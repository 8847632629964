import React, { useState } from 'react'

const  SubscribeForm = ({ props }) => {
  let [ email, setEmail ] = useState('')

  let subscribeEmail = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({"domain":"sultrysnaps","email":email,"token":"SABCD1001"});

    let requestOptions = {
      method: 'POST',
      mode:'no-cors',
      headers: myHeaders,
      body: raw
    };

    fetch("https://ygzf6kkqjb.execute-api.us-east-1.amazonaws.com/dev/subscribe", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return(
    <div id="subscribe-form">
      <input id="email-subscribe" placeholder="Keep in touch" onChange={event => setEmail(event.target.value)} name="email" />
      <button id="email-button" onClick={subscribeEmail}>Subscribe</button>
    </div>
  )
}

export default SubscribeForm
