import React from 'react'
import { Link } from 'gatsby'
import MainLayout from '~/layouts/MainLayout'
import SEO from '~/components/seo'
import Splash from '~/components/Splash'
import ProductGrid from '~/components/ProductGrid'
import SubscribeForm from '~/components/SubscribeForm'

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`edm`, `bass`, `raves`, `shop`, `merchandise`, `rave`, `warehouse`, `underground`]} />
    <MainLayout>
    <div className="content-wrapper">
      <div id="splash">
        <div className="splash-text">
            <h1> Sultry Snaps </h1>
            <p> Limited Edition drops for some of your favorite online stars. Every month a new inspiring piece based off a polaroid. Every purchase is entered to win the original polaroids. Proceeds are split between us, the star and a new chartiy supporting sex workers, dosmetic abuse victims or human traficking victims. </p>
          </div>
      </div>
      <SubscribeForm />
      <section id="store"><ProductGrid /></section>
    </div>
    </MainLayout>
  </>
)

export default IndexPage
// <section><button style={{ margin:'auto auto', display:'block' }}><Link to="/policies">Returns & Private Policy</Link></button></section>
